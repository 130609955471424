import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
import { SystemsDevopsAnalyze, Tools, CustomWorkloads } from '@carbon/pictograms-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`One of the key strengths of Kubernetes is its ability to manage containers at scale, allowing developers to easily deploy, manage, and scale containerized applications across multiple hosts and clusters.`}</p>
    <p>{`Kubernetes also provides a robust set of features and tools for application management, including load balancing, auto-scaling, rolling updates, and automated rollbacks. These features allow developers to easily manage and update applications with minimal downtime, ensuring a seamless user experience.`}</p>
    <p>{`Another strength of Kubernetes is its extensibility and flexibility, which allows developers to easily customize and extend the platform to fit their specific needs. This includes the ability to add custom plugins, controllers, and operators, as well as support for a wide range of container runtimes and infrastructure providers.`}</p>
    <p>{`Overall, Kubernetes is a powerful and flexible container orchestration system that provides a scalable and reliable platform for running and managing containerized applications. Its focus on automation, scalability, and extensibility make it a popular choice for organizations of all sizes, from small startups to large enterprise-level deployments.`}</p>
    <h2>{`Topics`}</h2>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard title="Static analysis" actionIcon="arrowRight" href="/devops/kubernetes/static-analysis/gatekeeper/" mdxType="ResourceCard">
      <SystemsDevopsAnalyze mdxType="SystemsDevopsAnalyze" />
    </ResourceCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard title="Tools" actionIcon="arrowRight" href="/devops/kubernetes/tools/kompose/" mdxType="ResourceCard">
      <Tools mdxType="Tools" />
    </ResourceCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard title="Workloads" actionIcon="arrowRight" href="/devops/kubernetes/workloads/argo/" mdxType="ResourceCard">
      <CustomWorkloads mdxType="CustomWorkloads" />
    </ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      